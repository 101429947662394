import { gql } from "@apollo/client";
import { GAME_PLAN_FEEDBACK_FRAGMENT } from "../preferences/preferences.gql";
import {
  DETAILED_BREAKDOWN_FRAGMENT,
  GAME_PLAN_OPTION_CASH_FLOW_FIELDS_FRAGMENT,
} from "@mc/common/blocks/daisy/detailed-breakdown/detailed-breakdown.gql";

const MONEY_FRAGMENT = gql`
  fragment MoneyFragment on Money {
    __typename
    formatted(wrapNegativeInBrackets: true)
    defaultFormatted: formatted
    amount
    isNegative
  }
`;

const PERCENTAGE_FRAGMENT = gql`
  fragment PercentageFragment on Percentage {
    __typename
    formatted
    value
  }
`;

const DAISY_DATABASE_STATES_ALTERED_PERCENTAGE_FRAGMENT = gql`
  fragment DaisyDatabaseStatesAlteredPercentageFragment on DaisyDatabaseStatesAlteredPercentage {
    ACT {
      formatted
    }
    NSW {
      formatted
    }
    NT {
      formatted
    }
    QLDInvestment {
      formatted
    }
    QLDOwner {
      formatted
    }
    SA {
      formatted
    }
    TAS {
      formatted
    }
    VICInvestment {
      formatted
    }
    VICOwner {
      formatted
    }
    WA {
      formatted
    }
  }
`;

const DAISY_DATABASE_STATES_MONEY_FRAGMENT = gql`
  fragment DaisyDatabaseStatesMoneyFragment on DaisyDatabaseStatesMoney {
    ACT {
      formatted
    }
    NSW {
      formatted
    }
    NT {
      formatted
    }
    QLD {
      formatted
    }
    SA {
      formatted
    }
    TAS {
      formatted
    }
    VIC {
      formatted
    }
    WA {
      formatted
    }
  }
`;

const DAISY_DATABASE_PURCHASE_LIMITATIONS_PER_STATE_FRAGMENT = gql`
  fragment DaisyDatabasePurchaseLimitationsPerStateFragment on DaisyDatabasePurchaseLimitationsPerState {
    purchasePriceMin {
      highGrowth {
        formatted
      }
      highGrowthAndReno {
        formatted
      }
      apartment {
        formatted
      }
      subDivisionTwo {
        formatted
      }
      subDivisionThree {
        formatted
      }
      grannyFlat {
        formatted
      }
    }
    purchasePriceMax {
      highGrowth {
        formatted
      }
      highGrowthAndReno {
        formatted
      }
      apartment {
        formatted
      }
      subDivisionTwo {
        formatted
      }
      subDivisionThree {
        formatted
      }
      grannyFlat {
        formatted
      }
    }
    weeklyIncomeMin {
      highGrowth {
        formatted
      }
      highGrowthAndReno {
        formatted
      }
      apartment {
        formatted
      }
      subDivisionTwo {
        formatted
      }
      subDivisionThree {
        formatted
      }
      grannyFlat {
        formatted
      }
    }
    weeklyIncomeMax {
      highGrowth {
        formatted
      }
      highGrowthAndReno {
        formatted
      }
      apartment {
        formatted
      }
      subDivisionTwo {
        formatted
      }
      subDivisionThree {
        formatted
      }
      grannyFlat {
        formatted
      }
    }
    rentIncrease100k {
      highGrowth {
        formatted
      }
      highGrowthAndReno {
        formatted
      }
      apartment {
        formatted
      }
      subDivisionTwo {
        formatted
      }
      subDivisionThree {
        formatted
      }
      grannyFlat {
        formatted
      }
    }
    timeToPurchase {
      highGrowth
      highGrowthAndReno
      apartment
      subDivisionTwo
      subDivisionThree
      grannyFlat
    }
    totalProjectCostMin {
      cosmeticHouse {
        formatted
      }
      cosmeticApartment {
        formatted
      }
      structural {
        formatted
      }
      grannyFlat {
        formatted
      }
    }
    totalProjectCostMax {
      cosmeticHouse {
        formatted
      }
      cosmeticApartment {
        formatted
      }
      structural {
        formatted
      }
      grannyFlat {
        formatted
      }
    }
    timeToComplete {
      cosmeticHouse
      cosmeticApartment
      structural
      subDivision
      newBuild
      grannyFlat
    }
    sqm {
      formatted
    }
  }
`;

const DAISY_FRAGMENT = gql`
  fragment DaisyFragment on Daisy {
    _id
    ...DetailedBreakdownFragment
    properties {
      _id
      propertyName

      # FOR MONTHLY CASHFLOW
      # fixedPropertyManagementRate {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      fixedPropertyManagementRateCurrent {
        ...MoneyFragment
      }
      fixedPropertyManagementRateDuring {
        ...MoneyFragment
      }
      fixedPropertyManagementRateLeased {
        ...MoneyFragment
      }
      fixedPropertyManagementRateSelfOccupied {
        ...MoneyFragment
      }
      weeklyRentCurrent {
        ...MoneyFragment
      }
      weeklyRentDuring {
        ...MoneyFragment
      }
      weeklyRentLeased {
        ...MoneyFragment
      }
      weeklyRentSelfOccupied {
        ...MoneyFragment
      }
      rentalYieldCurrent {
        ...PercentageFragment
      }
      rentalYieldDuring {
        ...PercentageFragment
      }
      rentalYieldLeased {
        ...PercentageFragment
      }
      rentalYieldSelfOccupied {
        ...PercentageFragment
      }
      # landTax {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      landTaxCurrent {
        ...MoneyFragment
      }
      landTaxDuring {
        ...MoneyFragment
      }
      landTaxLeased {
        ...MoneyFragment
      }
      landTaxSelfOccupied {
        ...MoneyFragment
      }
      # vacancyPeriodBuffer {
      #   ...DaisyMonthlyCashflowFragment
      # }
      vacancyPeriodBufferCurrent
      vacancyPeriodBufferDuring
      vacancyPeriodBufferLeased
      vacancyPeriodBufferSelfOccupied
      # maintenanceBufferOfPurchase {
      #   ...DaisyMonthlyCashflowPercentageFragment
      # }
      maintenanceBufferOfPurchaseCurrent {
        ...PercentageFragment
      }
      maintenanceBufferOfPurchaseDuring {
        ...PercentageFragment
      }
      maintenanceBufferOfPurchaseLeased {
        ...PercentageFragment
      }
      maintenanceBufferOfPurchaseSelfOccupied {
        ...PercentageFragment
      }
      rentReceivedCurrent {
        ...MoneyFragment
      }
      rentReceivedDuring {
        ...MoneyFragment
      }
      rentReceivedLeased {
        ...MoneyFragment
      }
      rentReceivedSelfOccupied {
        ...MoneyFragment
      }
      # rentReceivedCashflow {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      # holdingCostsCashflow {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      holdingCostsCurrent {
        ...MoneyFragment
      }
      holdingCostsDuring {
        ...MoneyFragment
      }
      holdingCostsLeased {
        ...MoneyFragment
      }
      holdingCostsSelfOccupied {
        ...MoneyFragment
      }
      # propertyManagement {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      propertyManagementCurrent {
        ...MoneyFragment
      }
      propertyManagementDuring {
        ...MoneyFragment
      }
      propertyManagementLeased {
        ...MoneyFragment
      }
      propertyManagementSelfOccupied {
        ...MoneyFragment
      }
      # advertisingForTenants {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      advertisingForTenantsCurrent {
        ...MoneyFragment
      }
      advertisingForTenantsDuring {
        ...MoneyFragment
      }
      advertisingForTenantsLeased {
        ...MoneyFragment
      }
      advertisingForTenantsSelfOccupied {
        ...MoneyFragment
      }
      # bodyCorporateFees {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      bodyCorporateFeesCurrent {
        ...MoneyFragment
      }
      bodyCorporateFeesDuring {
        ...MoneyFragment
      }
      bodyCorporateFeesLeased {
        ...MoneyFragment
      }
      bodyCorporateFeesSelfOccupied {
        ...MoneyFragment
      }
      # cleaning {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      cleaningCurrent {
        ...MoneyFragment
      }
      cleaningDuring {
        ...MoneyFragment
      }
      cleaningLeased {
        ...MoneyFragment
      }
      cleaningSelfOccupied {
        ...MoneyFragment
      }
      # councilRates {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      councilRatesCurrent {
        ...MoneyFragment
      }
      councilRatesDuring {
        ...MoneyFragment
      }
      councilRatesLeased {
        ...MoneyFragment
      }
      councilRatesSelfOccupied {
        ...MoneyFragment
      }
      # gardening {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      gardeningCurrent {
        ...MoneyFragment
      }
      gardeningDuring {
        ...MoneyFragment
      }
      gardeningLeased {
        ...MoneyFragment
      }
      gardeningSelfOccupied {
        ...MoneyFragment
      }
      # monthlyInsurance {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      monthlyInsuranceCurrent {
        ...MoneyFragment
      }
      monthlyInsuranceDuring {
        ...MoneyFragment
      }
      monthlyInsuranceLeased {
        ...MoneyFragment
      }
      monthlyInsuranceSelfOccupied {
        ...MoneyFragment
      }
      # monthlyLandTax {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      monthlyLandTaxCurrent {
        ...MoneyFragment
      }
      monthlyLandTaxDuring {
        ...MoneyFragment
      }
      monthlyLandTaxLeased {
        ...MoneyFragment
      }
      monthlyLandTaxSelfOccupied {
        ...MoneyFragment
      }
      # pestControl {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      pestControlCurrent {
        ...MoneyFragment
      }
      pestControlDuring {
        ...MoneyFragment
      }
      pestControlLeased {
        ...MoneyFragment
      }
      pestControlSelfOccupied {
        ...MoneyFragment
      }
      # travel {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      travelCurrent {
        ...MoneyFragment
      }
      travelDuring {
        ...MoneyFragment
      }
      travelLeased {
        ...MoneyFragment
      }
      travelSelfOccupied {
        ...MoneyFragment
      }
      # water {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      waterCurrent {
        ...MoneyFragment
      }
      waterDuring {
        ...MoneyFragment
      }
      waterLeased {
        ...MoneyFragment
      }
      waterSelfOccupied {
        ...MoneyFragment
      }
      # sundry {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      sundryCurrent {
        ...MoneyFragment
      }
      sundryDuring {
        ...MoneyFragment
      }
      sundryLeased {
        ...MoneyFragment
      }
      sundrySelfOccupied {
        ...MoneyFragment
      }
      # electricity {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      electricityCurrent {
        ...MoneyFragment
      }
      electricityDuring {
        ...MoneyFragment
      }
      electricityLeased {
        ...MoneyFragment
      }
      electricitySelfOccupied {
        ...MoneyFragment
      }
      # foxtel {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      foxtelCurrent {
        ...MoneyFragment
      }
      foxtelDuring {
        ...MoneyFragment
      }
      foxtelLeased {
        ...MoneyFragment
      }
      foxtelSelfOccupied {
        ...MoneyFragment
      }
      # loanRepaymentInterestComponent {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      loanRepaymentInterestComponentCurrent {
        ...MoneyFragment
      }
      loanRepaymentInterestComponentDuring {
        ...MoneyFragment
      }
      loanRepaymentInterestComponentLeased {
        ...MoneyFragment
      }
      loanRepaymentInterestComponentSelfOccupied {
        ...MoneyFragment
      }
      # loanRepaymentPrincipalAfterIo {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      loanRepaymentPrincipalAfterIoCurrent {
        ...MoneyFragment
      }
      loanRepaymentPrincipalAfterIoDuring {
        ...MoneyFragment
      }
      loanRepaymentPrincipalAfterIoLeased {
        ...MoneyFragment
      }
      loanRepaymentPrincipalAfterIoSelfOccupied {
        ...MoneyFragment
      }
      # maintenanceBufferToWallet {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      maintenanceBufferToWalletCurrent {
        ...MoneyFragment
      }
      maintenanceBufferToWalletDuring {
        ...MoneyFragment
      }
      maintenanceBufferToWalletLeased {
        ...MoneyFragment
      }
      maintenanceBufferToWalletSelfOccupied {
        ...MoneyFragment
      }
      # vacancyBufferToWallet {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      vacancyBufferToWalletCurrent {
        ...MoneyFragment
      }
      vacancyBufferToWalletDuring {
        ...MoneyFragment
      }
      vacancyBufferToWalletLeased {
        ...MoneyFragment
      }
      vacancyBufferToWalletSelfOccupied {
        ...MoneyFragment
      }
      # subTotalCashFlow {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      subTotalCashFlowCurrent {
        ...MoneyFragment
      }
      subTotalCashFlowDuring {
        ...MoneyFragment
      }
      subTotalCashFlowLeased {
        ...MoneyFragment
      }
      subTotalCashFlowSelfOccupied {
        ...MoneyFragment
      }
      # grandTotalCashFlow {
      #   ...DaisyMonthlyCashflowMoneyFragment
      # }
      grandTotalCashFlowCurrent {
        ...MoneyFragment
      }
      grandTotalCashFlowDuring {
        ...MoneyFragment
      }
      grandTotalCashFlowLeased {
        ...MoneyFragment
      }
      grandTotalCashFlowSelfOccupied {
        ...MoneyFragment
      }

      # FOR RENTAL INCOME
      weeklyRentCurrent {
        ...MoneyFragment
      }
      weeklyRentDuring {
        ...MoneyFragment
      }
      weeklyRentLeased {
        ...MoneyFragment
      }
      weeklyRentSelfOccupied {
        ...MoneyFragment
      }
      rentalYieldCurrent {
        ...PercentageFragment
      }
      rentalYieldDuring {
        ...PercentageFragment
      }
      rentalYieldLeased {
        ...PercentageFragment
      }
      rentalYieldSelfOccupied {
        ...PercentageFragment
      }

      # FOR BREAKDOWN DETAILS
      propertyName
      year

      # FOR EXISTING PROPERTY CARD
      monthlyGrossIncomeWorst: grossIncomeWorst(frequency: MONTHLY) {
        formatted(wrapNegativeInBrackets: true)
      }
    }
    summary {
      _id
      year
      accumulativeGrossIncomeWorst {
        ...MoneyFragment
      }
      monthlyAssumedGrossIncomeBest: assumedGrossIncomeBest(frequency: MONTHLY) {
        ...MoneyFragment
      }
      monthlyAssumedGrossIncomeWorst: assumedGrossIncomeWorst(frequency: MONTHLY) {
        ...MoneyFragment
      }
      monthlyHoldingCost: holdingCost(frequency: MONTHLY) {
        ...MoneyFragment
      }
      monthlyLoanCost: loanCost(frequency: MONTHLY) {
        ...MoneyFragment
      }
      monthlyRentReceived: rentReceived(frequency: MONTHLY) {
        ...MoneyFragment
      }
      monthlyWallet: wallet(frequency: MONTHLY) {
        ...MoneyFragment
      }
      roi {
        ...PercentageFragment
      }
      wallet {
        ...MoneyFragment
      }
    }
    comments
    inputJson
    databaseInputs {
      assumedCPI {
        formatted
      }
      averageAnnualRentalIncreaseHouses {
        ...DaisyDatabaseStatesAlteredPercentageFragment
      }
      averageAnnualRentalIncreaseUnits {
        ...DaisyDatabaseStatesAlteredPercentageFragment
      }
      averageAnnualRentalIncreaseCommercial {
        ...DaisyDatabaseStatesAlteredPercentageFragment
      }
      averageCapitalGrowthsHouses {
        ...DaisyDatabaseStatesAlteredPercentageFragment
      }
      averageCapitalGrowthsUnits {
        ...DaisyDatabaseStatesAlteredPercentageFragment
      }
      averageCapitalGrowthsCommercial {
        ...DaisyDatabaseStatesAlteredPercentageFragment
      }
      averageCapitalGrowthsHouseDiscountByState {
        ...DaisyDatabaseStatesAlteredPercentageFragment
      }
      averageCapitalGrowthsUnitDiscountByState {
        ...DaisyDatabaseStatesAlteredPercentageFragment
      }
      averageCapitalGrowthsCommercialDiscountByState {
        ...DaisyDatabaseStatesAlteredPercentageFragment
      }
      banksDiscountPredictions {
        ACT {
          formatted
        }
        NSW {
          formatted
        }
        NT {
          formatted
        }
        QLDInvestment {
          formatted
        }
        QLDOwner {
          formatted
        }
        SA {
          formatted
        }
        TAS {
          formatted
        }
        VICInvestment {
          formatted
        }
        VICOwner {
          formatted
        }
        WA {
          formatted
        }
      }
      forecastedCapitalGrowths {
        ACT {
          formatted
        }
        NSW {
          formatted
        }
        NT {
          formatted
        }
        QLD {
          formatted
        }
        SA {
          formatted
        }
        TAS {
          formatted
        }
        VIC {
          formatted
        }
        WA {
          formatted
        }
      }
      landToValueRatio {
        formatted
      }
      purchaseLimitations {
        ACT {
          ...DaisyDatabasePurchaseLimitationsPerStateFragment
        }
        NSW {
          ...DaisyDatabasePurchaseLimitationsPerStateFragment
        }
        NT {
          ...DaisyDatabasePurchaseLimitationsPerStateFragment
        }
        QLD {
          ...DaisyDatabasePurchaseLimitationsPerStateFragment
        }
        SA {
          ...DaisyDatabasePurchaseLimitationsPerStateFragment
        }
        TAS {
          ...DaisyDatabasePurchaseLimitationsPerStateFragment
        }
        VIC {
          ...DaisyDatabasePurchaseLimitationsPerStateFragment
        }
        WA {
          ...DaisyDatabasePurchaseLimitationsPerStateFragment
        }
      }
      rbaCashRates {
        formatted
      }
      sqmRatesBest {
        ...DaisyDatabaseStatesMoneyFragment
      }
      sqmRatesWorst {
        ...DaisyDatabaseStatesMoneyFragment
      }
      strategyAgeLimitation
    }
    existingPropertySummary {
      debt {
        formatted
      }
      equity {
        formatted
      }
    }
  }
  ${MONEY_FRAGMENT}
  ${PERCENTAGE_FRAGMENT}
  ${DAISY_DATABASE_STATES_ALTERED_PERCENTAGE_FRAGMENT}
  ${DAISY_DATABASE_STATES_MONEY_FRAGMENT}
  ${DAISY_DATABASE_PURCHASE_LIMITATIONS_PER_STATE_FRAGMENT}
  ${DETAILED_BREAKDOWN_FRAGMENT}
`;

const OPTION_FRAGMENT = gql`
  fragment OptionFragment on GamePlanOption {
    _id
    ...GamePlanOptionCashFlowFieldsFragment
    sequence
    basedOn
    basedOnFeedback {
      ...GamePlanFeedbackFragment
    }
    name
    description
    summary
    tags
    isNarrativeCompleted
    chosenByClient
    publishedAt {
      formattedDate: formatted(format: "ddd DD MMM YYYY")
      formattedTime: formatted(format: "HH:mm")
    }
    round
    events {
      _id
      type
      label
      startAt {
        origin
        formatted(format: "YYYY-MM-DD")
        formattedYear: formatted(format: "YYYY")
        formattedYearAndMonth: formatted(format: "YYYY-MM")
      }
      endAt {
        year: formatted(format: "YYYY")
      }
      property {
        _id
        purchaseType
        propertyType
        location
        description
        budget {
          amount
          formatted
        }
        loan {
          repaymentType
          originalAmount {
            amount
          }
          originalInterestRate {
            value
          }
          originalLoanTermInYears
        }
        deposit {
          value
          formatted
        }
        fundingSources {
          propertyId
          amount {
            amount
          }
        }
        preProjectRent {
          amount
          formatted
        }
        purposeChangeAt {
          year: formatted(format: "YYYY")
        }
      }
      project {
        _id
        type
        propertyId
        budget {
          amount
          formatted
        }
        budgetWithCPI {
          formatted
        }
        assumedROI {
          value
          formatted
        }
        loan {
          repaymentType
          originalAmount {
            amount
          }
          originalInterestRate {
            value
          }
          originalLoanTermInYears
        }
        deposit {
          value
          formatted
        }
        fundingSources {
          propertyId
          amount {
            amount
          }
        }
        postProjectRent {
          amount
          formatted
        }
      }
      sale {
        _id
        propertyId
        price {
          formatted
        }
        fundsAvailable {
          formatted
        }
      }
      loanAdjustment {
        _id
        type
        propertyId
        fund {
          amount
          formatted
        }
      }
    }
    combinedProperties {
      _id
      propertyId
      label
      isExisting
      events {
        _id
        type
        startAt {
          formatted(format: "YYYY-MM-DD")
        }
      }
      isSold
    }
    daisy {
      ...DaisyFragment
    }
  }
  ${DAISY_FRAGMENT}
  ${GAME_PLAN_FEEDBACK_FRAGMENT}
  ${GAME_PLAN_OPTION_CASH_FLOW_FIELDS_FRAGMENT}
`;

export const GET_GAME_PLAN = gql`
  query GetGamePlan($clientId: String!) {
    gamePlan(clientId: $clientId) {
      _id
      status
      description
      isEditable
      startAt {
        year: formatted(format: "YYYY")
      }
      endAt {
        year: formatted(format: "YYYY")
      }
      options {
        ...OptionFragment
      }
      chosenOption {
        _id
      }
    }
  }
  ${OPTION_FRAGMENT}
`;

export const UPDATE_GAME_PLAN = gql`
  mutation UpdateGamePlan($gamePlanId: String!, $updateGamePlanInput: UpdateGamePlanInput!) {
    updateGamePlan(gamePlanId: $gamePlanId, updateGamePlanInput: $updateGamePlanInput) {
      _id
      description
    }
  }
`;

export const CREATE_GAME_PLAN_OPTION = gql`
  mutation CreateGamePlanOption($gamePlanId: String!) {
    createGamePlanOption(
      gamePlanId: $gamePlanId
      gamePlanOption: { name: "To be renamed", description: "", summary: "" }
    ) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const UPDATE_GAME_PLAN_OPTION = gql`
  mutation UpdateGamePlanOption($gamePlanOptionId: String!, $gamePlanOption: UpdateGamePlanOptionInput!) {
    updateGamePlanOption(gamePlanOptionId: $gamePlanOptionId, gamePlanOption: $gamePlanOption) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const UPSERT_GAME_PLAN_OPTION_EVENT = gql`
  mutation UpsertGamePlanOptionEvent(
    $gamePlanOptionId: String!
    $gamePlanEventId: String
    $gamePlanEvent: GamePlanEventInput!
  ) {
    upsertGamePlanOptionEvent(
      gamePlanOptionId: $gamePlanOptionId
      gamePlanEventId: $gamePlanEventId
      gamePlanEvent: $gamePlanEvent
    ) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const ASK_DAISY = gql`
  mutation AskDaisy($gamePlanOptionId: String!) {
    askDaisy(gamePlanOptionId: $gamePlanOptionId) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const SET_GAME_PLAN_PROPERTY_RENTAL_INCOME = gql`
  mutation SetGamePlanPropertyRentalIncome(
    $gamePlanOptionId: String!
    $gamePlanEventId: String!
    $gamePlanPropertyRentalIncomeInput: GamePlanPropertyRentalIncomeInput!
  ) {
    setGamePlanPropertyRentalIncome(
      gamePlanEventId: $gamePlanEventId
      gamePlanOptionId: $gamePlanOptionId
      gamePlanPropertyRentalIncomeInput: $gamePlanPropertyRentalIncomeInput
    ) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const UNSET_GAME_PLAN_PROPERTY_RENTAL_INCOME = gql`
  mutation UnsetGamePlanPropertyRentalIncome($gamePlanOptionId: String!, $gamePlanEventId: String!) {
    unsetGamePlanPropertyRentalIncome(gamePlanEventId: $gamePlanEventId, gamePlanOptionId: $gamePlanOptionId) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const SET_GAME_PLAN_PROJECT_RENTAL_INCOME = gql`
  mutation SetGamePlanProjectRentalIncome(
    $gamePlanOptionId: String!
    $gamePlanEventId: String!
    $gamePlanProjectRentalIncomeInput: GamePlanProjectRentalIncomeInput!
  ) {
    setGamePlanProjectRentalIncome(
      gamePlanEventId: $gamePlanEventId
      gamePlanOptionId: $gamePlanOptionId
      gamePlanProjectRentalIncomeInput: $gamePlanProjectRentalIncomeInput
    ) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const UNSET_GAME_PLAN_PROJECT_RENTAL_INCOME = gql`
  mutation UnsetGamePlanProjectRentalIncome($gamePlanOptionId: String!, $gamePlanEventId: String!) {
    unsetGamePlanProjectRentalIncome(gamePlanEventId: $gamePlanEventId, gamePlanOptionId: $gamePlanOptionId) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const DELETE_GAME_PLAN_OPTION_EVENT = gql`
  mutation DeleteGamePlanOptionEvent($gamePlanOptionId: String!, $gamePlanEventId: String!) {
    deleteGamePlanOptionEvent(gamePlanOptionId: $gamePlanOptionId, gamePlanEventId: $gamePlanEventId) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const REFRESH_GAME_PLAN_OPTION_DAISY = gql`
  mutation RefreshGamePlanOptionDaisy($gamePlanOptionId: String!) {
    refreshGamePlanOptionDaisy(gamePlanOptionId: $gamePlanOptionId) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const DUPLICATE_GAME_PLAN_OPTION = gql`
  mutation DuplicateGamePlanOption($gamePlanOptionId: String!) {
    duplicateGamePlanOption(gamePlanOptionId: $gamePlanOptionId) {
      ...OptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;

export const DELETE_GAME_PLAN_OPTION = gql`
  mutation DeleteGamePlanOption($gamePlanOptionId: String!) {
    deleteGamePlanOption(gamePlanOptionId: $gamePlanOptionId)
  }
`;

export const GET_ACTIVE_PROFILE_EXISTING_PROPERTIES = gql`
  query GetActiveProfileExistingProperties($clientId: String!) {
    client(id: $clientId) {
      _id
      profile(status: ACTIVE) {
        _id
        createdAt {
          formatted(format: "YYYY")
        }
        services
        totalCurrentValue {
          formatted
        }
        accumulatedInvestmentAmounts {
          formatted
        }
        finance {
          upfrontInvestment {
            formatted
          }
          expectedPassiveIncome
          lumpSumInvestments {
            amount {
              formatted
            }
            investedAt {
              year: formatted(format: "YYYY")
            }
          }
          monthlyExpenseContributions {
            formatted
            periodInYear
          }
        }
        familyHome {
          purchaseAmountFormatted
          purchaseYear
        }
        existingProperties {
          _id
          address {
            formatted(country: false)
          }
          purchasedAt {
            formatted(format: "YYYY-MM-DD")
          }
          purchaseAmountFormatted {
            formatted
          }
          currentValueFormatted {
            formatted
          }
          loans {
            originalAmount {
              amount
            }
          }
          details {
            id
            coreAttributes {
              beds
              baths
              carSpaces
              propertyType
            }
            location {
              singleLine
            }
            images {
              defaultImage {
                mediumPhotoUrl
              }
            }
          }
          excludeFromGamePlan
          domainPropertyDetails {
            id
            address
            bedrooms
            bathrooms
            carSpaces
            propertyType
            photos {
              fullUrl
            }
          }
        }
      }
    }
  }
`;

export const GET_FUNDING_SOURCE_OPTIONS = gql`
  query GetFundingSourceOptions($clientId: String!, $year: Int!) {
    gamePlan(clientId: $clientId) {
      _id
      options {
        _id
        totalFundsAvailable(year: $year) {
          amount
          formatted
        }
        fundingSourceOptions(year: $year) {
          _id
          propertyId
          label
          maxAmount {
            amount
            formatted
          }
        }
      }
    }
  }
`;

export const PUBLISH_GAME_PLAN = gql`
  mutation PublishGamePlan($gamePlanId: String!) {
    publishGamePlan(gamePlanId: $gamePlanId) {
      _id
      status
    }
  }
`;

export const DAISY_INTEREST_RATE = gql`
  query DaisyInterestRate($gamePlanOptionId: String!, $daisyInterestRateInput: DaisyInterestRateInput!) {
    daisyInterestRate(gamePlanOptionId: $gamePlanOptionId, daisyInterestRateInput: $daisyInterestRateInput) {
      interestRate {
        value
      }
    }
  }
`;

export const DAISY_RENOVATION = gql`
  query DaisyRenovation($daisyRenovationInput: DaisyRenovationInput!) {
    daisyRenovation(daisyRenovationInput: $daisyRenovationInput) {
      totalConstructionFundsRequired {
        formatted
        amount
      }
      renovationBudgetIncPMFee {
        amount
      }
    }
  }
`;
